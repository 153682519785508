import { graphql } from 'gatsby';
import { useI18next, useTranslation } from 'gatsby-plugin-react-i18next';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';

import { CreditCard } from 'react-bootstrap-icons';
import { FilePerson } from 'react-bootstrap-icons';
import { Pencil } from 'react-bootstrap-icons';

import ContainerDonts from '../components/framework/container-donts';
import Framework from '../components/framework';
import Seo from '../components/framework/seo';
import withLocation from '../middleware/withLocation';

const Book = ({ location }) => {
	const { language } = useI18next();
	const { t } = useTranslation('book');

	useEffect(() => {
		if (!window.cbk) {
			window.cbk = function () {
				window.cbk.p.push(arguments);
			};
		}
		window.cbk.p = [];

		const script = document.createElement('script');
		script.async = true;
		script.src = 'https://cliento.com/widget-v2/cliento.js';

		document.body.appendChild(script);
		window.cbk('id', '3VSt6wrs8lrJ77AmnVzZj');
		window.cbk('locale', language == 'sv' ? 'sv' : 'en');
		/*window.cbk('onCompleted', function (booking) {
			const url =
				'/register/?date=' +
				encodeURIComponent(booking.date) +
				'&location=' +
				encodeURIComponent(booking.location) +
				'&step=booking&time=' +
				encodeURIComponent(booking.time);
			window.history.pushState({}, '', url);
		});*/

		return () => {
			document.body.removeChild(script);
			delete window.cbk;
		};
	}, []);

	return (
		<Framework location={location}>
			<Seo
				description={t('meta_description')}
				tags={t('meta_keywords')}
				title={t('title')}
			/>
			<div className="container mt-3 pt-lg-5 mb-5">
				<h1 className="h3">{t('t1')}</h1>
				<div>
					<ul className="list-group list-group-flush list-group-horizontal-md">
						<li className="list-group-item x-small p-2 py-1 py-md-2 border-0 bg-primary-light">
							<span className="me-1 h6">
								<CreditCard />
							</span>
							<span>{t('t2')}</span>
						</li>
						<li className="list-group-item x-small p-2 py-1 py-md-2 border-0 bg-primary-light">
							<span className="me-1 h6">
								<FilePerson />
							</span>
							<span>{t('t3')}</span>
						</li>
						<li className="list-group-item x-small p-2 py-1 py-md-2 border-0 bg-primary-light">
							<span className="me-1 h6">
								<Pencil />
							</span>
							<span>{t('t4')}</span>
						</li>
					</ul>
				</div>
				<div className="mt-4 mb-5" id="cliento-booking"></div>
				<div className="bg-light rounded p-4 my-5 ">
					<ContainerDonts />
				</div>
			</div>
		</Framework>
	);
};

Book.propTypes = {
	location: PropTypes.object.isRequired,
};

export default withLocation(Book);

export const query = graphql`
	query {
		locales: allTranslation(filter: { id: { ne: "dummy" } }) {
			...translationFields
		}
	}
`;
